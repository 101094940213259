<template>
  <div class="join-complete">
    <div class="content">
      <i>🎉</i>
      <div class="info">
        <b>오마이컴퍼니</b>의 회원가입을 완료하였습니다
      </div>
      <div class="action">
        <router-link to="/reward/list" class="btn btn-point font-sm mr-2" v-if="$route.path === '/'">프로젝트 둘러보기</router-link>
        <a class="btn btn-point font-sm mr-2" @click="$store.commit('closeModal', {name: component.name})" v-else>계속 진행하기</a>
        <router-link to="/mypage" class="btn btn-secondary font-sm mr-2">마이페이지</router-link>
      </div>
    </div>
    <div class="background">
      <Confetti/>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import Confetti from "@/components/Confetti";
import {useGtm} from "@gtm-support/vue2-gtm";

function Component(initialize) {
  this.name = "modalJoinComplete";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Confetti},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      // GTM으로 이벤트 전달
      const loggingMsg = `Event Member Registration Completed`;

      try {
        const gtm = useGtm();
        if (gtm.enabled()) {
          let dataLayerEvent = "EventMemberCompleteRegistration";
          window.dataLayer?.push({
            event: dataLayerEvent,
          });
        }
      } catch (err) {
        // 데이터 전송이 실패하면 콘솔로그출력
        console.warn(`Failed ${loggingMsg} - ${err}`);
      }

      store.commit("tuneModal", {component, size: "sm"});
    });

    return {component};
  },
});
</script>

<style lang="scss" scoped>
.join-complete {
  background: #fff;

  .content {
    padding: $px55 $px15 $px55 0;
    text-align: center;

    i {
      font-size: 3.5rem;
      font-style: normal;
    }

    .info {
      padding-top: $px20;
    }

    .action {
      padding-top: $px30;

      .btn {
        padding: 0 $px15;
        height: $formHeightLg;
        line-height: calc(#{$formHeightLg} - 2px);
      }
    }
  }

  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
</style>